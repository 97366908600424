
const tg = window.Telegram.WebApp;

export function useTelegram() {

  const tgOnClose = () => {tg.close();}

  return {
      tg: tg, 
      tgClose: tgOnClose,
      user: tg.initDataUnsafe?.user,
      tgMainButton: tg.MainButton,
      queryId: tg.initDataUnsafe?.query_id
    }
}
