import React from "react";
import "./register.css";
import { Button } from "../button/button";
import { useState } from "react";
import { gcHostURL } from "../../global_def";

export const Register = (props) => {

  const [user, setUser] = useState({
    login: "",
    pass: "",
    passConf: "",
    email: "",
    phone: "",
  })
  const[mess, setMess] = useState("");

  const onChangeLogin = (e) =>{
    setUser(user => {return {...user, login: e.target.value}});
  }
  const onChangePassword = (e) =>{
    setUser(user => {return{...user, pass: e.target.value}});
  }
  const onChangePasswordConfirm = (e) =>{
    setUser(user => {return{...user, passConf: e.target.value}});
  }
  const onChangeEmail = (e) =>{
    setUser(user=>{return{...user, email:e.target.value}});
  }
  const onChangePhone = (e) =>{
    setUser(user=>{return{...user, phone: e.target.value}});
  }
  const onRegister = () =>{
    if(user.pass!==user.passConf) {
      setMess("Пароли не совпадают");
      return;
    }
    const regExpP = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[^a-zA-Z\\d]).{8,}$");
    if(!regExpP.test(user.pass)) {
      setMess(`${user.pass} пароль мин 8 символов, одна заглавная, одна цифра. один спец.симв.`)
      return;
    }
    fetch(`${gcHostURL}/api/v1/User/Register`, {
      method: "post",
      mode: "cors",
      body: JSON.stringify({Username: user.login, Email: user.email, Phone: user.phone, Password: user.pass,}),
      headers: {
        "Content-Type": "application/json",
      }
    })
    .then(res =>{
      if(res.status!==200) throw (new Error(`server responce state ${res.status}`));
      return res.json();
    })
    .then(responce => {
      setMess("");
    })
    .catch(error => {
      setMess(error.message);
    })

  }
  return (
    <div className={'register'}>
      Регистрация пользователя
      <input 
        className="reginput"
        type="text"
        placeholder="имя пользователя"
        onChange={onChangeLogin}
        required
      />
      <input
        className="reginput"
        required
        type="password"
        placeholder="пароль"
        onChange={onChangePassword}
      />
      <input
        className="reginput"
        required
        type="password"
        placeholder="пароль еще раз"
        onChange={onChangePasswordConfirm}
      />
      <input
        className="reginput"
        required
        type="email"
        placeholder="электронная почта"
        onChange={onChangeEmail}
      />
      <input
        className="reginput"
        required
        type="text"
        placeholder="номер телефона"
        onChange={onChangePhone}
      />
      <Button className={'reg'} onClick={onRegister}>Зарегистрироваться</Button>
      {mess.length > 0 && mess}
    </div>
  )
}