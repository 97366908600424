import React, { useState, useEffect, useCallback } from "react";
import "./showcase.css"
import { Card } from "../card/card";
import { gcHostURL } from "../../global_def";
import { useTelegram } from "../../tgExp";
import { Button } from "../button/button";

let cart = [];
let total = 0;
export const ShowCase = (props) =>
{
  const {tgMainButton, tg, queryId} = useTelegram();
  const [cartItems, setCartItems] = useState([]);
    
  const [totalPrice, setTotalPrice] = useState(0);
  const [products, setProducts] = useState([]);
//     {
//       id: "",
//       name: "",
//       price: "",
//       description: "",
//       image: {}
//     }
 const SendData = useCallback(_ => {
  const resp = {
    queryId: queryId,
    items: cart,
    total: total,
  };
  
  fetch(`${gcHostURL}/bot`,{
    method: "post",
    //mode: "no-cors",
    body: JSON.stringify(resp),
    
    headers: {
      "Content-Type": "application/json",
    }
  })
  .then(res => {
    console.log(res);
    if(res.status !== 200) throw (new Error(`$server responce with ${res.status}`));
    return res.json();
  })
  .then(responce => {
    
    
  })
  .catch(err => {
    const b = 9;
  });

  tg.close();
});

  useEffect(_=> {
    tg.onEvent('mainButtonClicked', SendData)
    return _ =>{tg.offEvent('mainButtonClicked', SendData)}
  }, []);

  
  useEffect(_ => {
    const tp = cartItems.reduce((acc, item) => acc += item.price*item.quantity, 0)
    setTotalPrice(tp);
    cartItems.length > 0 ? tgMainButton.show() : tgMainButton.hide();
    tgMainButton.setParams({text: `Оформить заказ на  ${tp} руб.`});
    cart = cartItems.map(item => {return item});
    total = tp;
  }, [cartItems]);

  useEffect(_ => {
    GetProducts();
  }, []);

  const GetProducts = () => {
    fetch(`${gcHostURL}/api/v1/products`,{
      method: "get",
      headers: {
        "Content-Type": "application/json",
      }
    })
    .then(res => {
      console.log(res);
      if(res.status !== 200) throw (new Error(`$server responce with ${res.status}`));
      return res.json();
    })
    .then(responce => {
      const prod = responce.value.map(item => {return {id: item.id, name: item.name, price: item.price, description: item.description}})
      setProducts(state => {return prod});
    })
    .catch(err => {
      const b = 9;
    });

  }

  const onAdd = (product) => {
    const prodExist = cartItems.find(item => item.id === product.id);
    if(prodExist) {
      setCartItems(cartItems => 
        cartItems.map(item => {
         return item.id === product.id ? {...prodExist, quantity: prodExist.quantity+1} : item;
        })
      );
    } else {
      setCartItems(cartItems => [...cartItems, {...product, quantity: 1}])
    }
  };
  const onRemove = (product) => {
    const prodExist = cartItems.find(item => item.id === product.id);
    if(prodExist.quantity === 1){
      setCartItems(cartItems => cartItems.filter(item=> item.id !== product.id));
    } else {
      setCartItems(cartItems => cartItems.map(item => {
        return item.id === product.id ? {...prodExist, quantity: prodExist.quantity-1} : item;}))
    }
  };

  return(
    <div>
    <div className={'list'} >
      {products.map(item => (
        <Card key={item.id} product={item} onAdd={onAdd} onRemove={onRemove} className={'item'} />
      ))} 
    </div>
        
    </div>
  )
//   <Button onClick={chbs}>check</Button>
//         {cartItems.length > 0 && cartItems[0].name}
 }
