import { Button } from "../button/button";
import "./login.css";
import React, { useEffect, useState } from "react";
import { gcHostURL } from "../../global_def";
import { useDispatch, useSelector } from "react-redux";
import { userLogin } from "../../redux/action";
import {json, useNavigate} from "react-router-dom";
import {useTelegram} from "../../tgExp.js";

export const Login = (props) => {

  const navigate = useNavigate();
    const [user, setUser] = useState({
    login: "",
    password: "",
    token:"",
    errorStr: "",
  });

  const {tg} = useTelegram();
  useEffect( _=> {
    tg.ready();
  }, []);

  const dispath = useDispatch();
  const userData = useSelector(state=>state.user);

  const onChangeLogin = (e) =>{
    setUser(user => {return{...user, login: e.target.value}});
  }

  const onChangePassword = (e) =>{
    setUser(user => {return{...user, password: e.target.value}});
  }
  const fuData = new FormData();
  fuData.append("Username", user.login);
  fuData.append("Password", user.password);
  
  const onRegister = () =>{
    fetch(`${gcHostURL}/api/v1/User/Login`,{
      method: "post",
      body: fuData,
      headers: {
        //"Content-Type": "application/json",
      }
    })
    .then(res=>{
      if(res.status!==200) throw (new Error(`server responce state ${res.status}`));
      return res.json();
    })
    .then(responce=> {
      setUser(user=> {return{...user, token: responce.token}});
      const userPL = {login: user.login, token: responce.token};
      dispath(userLogin(userPL));
      //navigate("/");
      tg.sendData(JSON.stringify(userPL));
    })
    .catch(error =>{
      setUser(user => {return{...user, login:"", ticket:"", errorStr: error.message}});
    })
  }

  return(
    <div className="login">
      Login
      <input 
        className="lginput"
        type="text" 
        required 
        id="login" 
        placeholder="имя пользователя" 
        onChange={onChangeLogin}
      />
      <input 
        className="lginput"
        type="password" 
        required 
        id="password" 
        placeholder="пароль"
        onChange={onChangePassword}
      />
      <div className="lgbutton_box">
        <Button className={'lgn'} onClick={onRegister}>Войти</Button>
        <Button className={'reg'} onClick={()=> navigate("/register")}>Зарегистрироваться</Button>
       </div> 
      {user.errorStr.length > 0 && <label>{user.errorStr}</label>}
    </div>
  );
}