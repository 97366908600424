import React, { useState } from "react";
import "./card.css";
import { Button } from "../button/button";

export const Card = ({product, onAdd, onRemove}) =>{

  const [count, setCount] = useState(0);
  const handleIncrement = () => {
    setCount(count + 1);
    onAdd(product);
  }
  const handleDecrement = () => {
    setCount(count - 1);
    onRemove(product);
  }
  
  return(
    <div className={'card'}>
      <span className={`${count !== 0 ? "card__badge" : "card__badge-hidden"}`} >
        {count}
      </span>
      <div className={'img__container'}>
        <img src={product.image} alt={product.name} />
      </div>
      <h4 className={'title'}>{product.name}</h4>
      <div className={'description'}>{product.description}</div>
      <div className={'price'}>
        <span>Цена: <b>{product.price}</b></span>
      </div>
      <div className={'btn__container'}>
        <Button className={'add'} onClick={handleIncrement}>Добавить</Button>
        {count !== 0 ?
          <Button className={'rmv'} onClick={handleDecrement}>Удалить</Button>
          : ""
        }
      </div>
    </div>
  )
}