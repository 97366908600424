import React from "react";
import "./header.css"
import { useTelegram } from "../../tgExp";
import { Button } from "../button/button";
import { useSelector } from "react-redux";

export const Header = () => {
  const {tgClose, user, tg, queryId} = useTelegram();

  const sd = _ => {tgClose()};
  const userData = useSelector(state=>state.user);

  return (
    <div className={'header'}>
      <h3><b>{`user ${user?.id}`}</b></h3>
      <Button className={'cls'} onClick={sd}>Закрыть</Button>
    </div>
  )
}