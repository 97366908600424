//export const gcHostURL="http://localhost:5000";
export const gcHostURL="https://bustlergreen.ru";

export const SESSION_STOR_USER_KEY = "team2.ui.user.data";

export function formatDateTime(date){
    return `${(date.getDate()<10?"0":"")+date.getDate()}-${((date.getMonth()+1)<10?"0":"") +(date.getMonth()+1)}-${date.getFullYear()} ${(date.getHours()<10?"0":"") + date.getHours()}:${(date.getMinutes()<10?"0":"") + date.getMinutes()}`;
  }
  export function formatDate(date){
    return `${(date.getDate()<10?"0":"")+date.getDate()}-${((date.getMonth()+1)<10?"0":"") +(date.getMonth()+1)}-${date.getFullYear()} `;
  }