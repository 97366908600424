import { combineReducers } from "@reduxjs/toolkit";
import { USER_LOGIN, USER_LOGOUT, userLogin, userLogout } from "./action";

const userData ={
  login: "",
  token:"",
};
function user(state=userData, action){
  switch(action.Type){
    case USER_LOGIN:{
      return{
        ...state, 
          login: action.payload.login,
          token: action.payload.token
      };
    };
    case USER_LOGOUT:{
      return {
        ...state,
          login: "",
          token: "",
      };
    };
    default: {};
  };
  return state;
};

export const reducer = combineReducers({user});