import React from "react";
import "./form.css"

export const Form  = () => {
  return(
    <div className={'form'}>
      <h4>User data</h4>
      <input className={'input'} type='text' placeholder={'City'}/>
      <input className={'input'} type='text' placeholder={'Street'}/>
    </div>
  )
}