import { useEffect } from 'react';
import './app.css';
import {Header} from "./components/header/header";
import { useTelegram } from './tgExp';
import { Route, Routes } from 'react-router-dom';
import { Form } from './components/form/form';
import { ShowCase } from './components/showcase/showcase';
import { Login } from './components/login/login';
import { Register } from './components/register/register';

function App() {
  
  const {tg} = useTelegram();
  
  useEffect(()=> {
    tg.ready();
  }, [])

  return (
    <div>
      <Header/>
      <Routes>
        <Route index element={<ShowCase/>}/>
        <Route path={'/form'} element={<Form/>}/>
        <Route path={'/login'} element={<Login/>}/>
        <Route path={'/register'} element={<Register/>}/>
      </Routes>
    </div>
  );
}

export default App;